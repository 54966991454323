import { Radar } from './Radar';
import { PolarAngleAxis } from './PolarAngleAxis';
import { PolarRadiusAxis } from './PolarRadiusAxis';
import { formatAxisMap } from './util/PolarUtils';
import { generateCategoricalChart } from './CategoricalChart';

export const RadarChart = generateCategoricalChart({
  chartName: 'RadarChart',
  GraphicalChild: Radar,
  axisComponents: [
    { axisType: 'angleAxis', AxisComp: PolarAngleAxis },
    { axisType: 'radiusAxis', AxisComp: PolarRadiusAxis },
  ],
  formatAxisMap,
  defaultProps: {
    layout: 'centric',
    startAngle: 90,
    endAngle: -270,
    cx: '50%',
    cy: '50%',
    innerRadius: 0,
    outerRadius: '80%',
  },
});
