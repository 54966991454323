export class IdHelper {
  private DEFAULT_RANDOM_ID_LEN = 8;

  // ID Generator Dictionary.
  // currently uses only alphabets and digits.
  private DICT_RANGES = {
    digits: [48, 58],
    lowerCase: [97, 123],
    upperCase: [65, 91],
  };

  private get dictLength() { return this.dict.length; };
  private counter = 0;

  // Generate Dictionary.
  dict = [];

  /**
   *
   */
  constructor() {
    let _i;
    let dictIndex = _i = 0;
    let rangeType;
    for (rangeType in this.DICT_RANGES) {
      const dictRange = this.DICT_RANGES[rangeType];
      const lowerBound = dictRange[0], upperBound = dictRange[1];
      for (dictIndex = _i = lowerBound; lowerBound <= upperBound ? _i < upperBound : _i > upperBound; dictIndex = lowerBound <= upperBound ? ++_i : --_i) {
        this.dict.push(String.fromCharCode(dictIndex));
      }
    }

    // Shuffle Dictionary for removing selection bias.
    this.dict = this.dict.sort(() => Math.random() <= 0.5 ? 1 : 0);

    // Cache Dictionary Length for future usage.
    // this.dictLength = this.dict.length;
  }

  /**
    * Generates UUID based on internal counter that's incremented after each ID generation.
    */
  sequentialId() {
    let counterDiv;
    let counterRem;
    let id = '';

    counterDiv = this.counter;
    while (true) {
      counterRem = counterDiv % this.dictLength;
      counterDiv = parseInt((counterDiv / this.dictLength).toString(), 10);
      id += this.dict[counterRem];
      if (counterDiv === 0) {
        break;
      }
    }
    this.counter += 1;
    return id;
  };

  /**
    * Generates UUID by creating each part randomly.
    * @param {Integer} uuidLength Desired UUID length.
    */
  randomId(uuidLength?: number) {
    let id = '';
    let randomPartIdx;
    let _j;
    if (uuidLength === null || typeof uuidLength === 'undefined') {
      uuidLength = this.DEFAULT_RANDOM_ID_LEN;
    }
    if ((uuidLength === null || typeof uuidLength === 'undefined') || uuidLength < 1) {
      throw new Error('Invalid UUID Length Provided');
    }

    // Generate random ID parts from Dictionary.
    for (let _idIndex = _j = 0; 0 <= uuidLength ? _j < uuidLength : _j > uuidLength; _idIndex = 0 <= uuidLength ? ++_j : --_j) {
      randomPartIdx = ((Math.random() * this.dictLength) % this.dictLength).toFixed(0);
      if (randomPartIdx > this.dict.length) {
        randomPartIdx = this.dict.length - 1;
      }
      id += this.dict[randomPartIdx];
    }

    // Return random generated ID.
    return id;
  };
};
