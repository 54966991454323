import { EntryCollection } from 'contentful';
import { createContext, useState } from 'react';
import { CapabilityModel } from '~/models/Capability';
import { TitleModel } from '~/models/Title';
import { ContentService } from '~/services';
import { IKeyValuePair, State } from '.';
import { AssessmentModel, CompetencyModel } from '~/models';

type TNotificationState = { open: boolean, message: string | undefined, type: 'success' | 'error' | undefined };

interface IDataContextState {
  assessmentCompleteState?: State<boolean>,
  assessmentState?: State<AssessmentModel | undefined>,
  assessmentsState?: State<AssessmentModel[] | undefined>,
  capabilityState?: State<CapabilityModel | undefined>,
  competenciesState?: State<CompetencyModel[] | undefined>,
  contentState?: State<EntryCollection<any> | undefined>,
  contentService?: ContentService,
  showAssessmentsState?: State<boolean>,
  notificationState?: State<TNotificationState>,
  titleState?: State<TitleModel | undefined>,
  reset?: () => Promise<boolean>;
}

interface IDataProviderModel {
  children: any
}

export const Tag: IKeyValuePair<string> = {
  'data-engineering': 'dataEngineering',
  'product-management': 'productManagement',
  'product-operations': 'productOperations',
  'quality-engineering': 'qualityEngineering',
  'software-engineering': 'softwareEngineering',
  'solution-engineering': 'solutionEngineering',
  'site-reliability-engineering': 'siteReliabilityEngineering',
}

const DataContext = createContext<IDataContextState>({});

const DataProvider: React.FC<IDataProviderModel> = ({ children }) => {
  const assessmentCompleteState = useState<boolean>(false);
  const assessmentState = useState<AssessmentModel | undefined>();
  const assessmentsState = useState<AssessmentModel[] | undefined>([]);
  const competenciesState = useState<CompetencyModel[] | undefined>();
  const contentService = new ContentService();
  const contentState = useState<EntryCollection<any> | undefined>();
  const capabilityState = useState<CapabilityModel | undefined>();
  const showAssessmentsState = useState<boolean>(false);
  const notificationState = useState<TNotificationState>({ open: false, message: undefined, type: undefined });
  const titleState = useState<TitleModel | undefined>();

  const reset = (): Promise<boolean> => {
    return new Promise(resolve => {
      assessmentCompleteState[1](false);
      assessmentState[1](undefined);
      assessmentsState[1](undefined);
      capabilityState[1](undefined);
      competenciesState[1](undefined);
      contentState[1](undefined);
      titleState[1](undefined);
      resolve(true);
    });
  }

  return (
    <DataContext.Provider
      value={{
        assessmentCompleteState,
        assessmentState,
        assessmentsState,
        capabilityState,
        competenciesState,
        contentState,
        contentService,
        reset,
        showAssessmentsState,
        notificationState,
        titleState,
      }}
    >
      {children}
    </DataContext.Provider>
  )
};

export {
  DataContext,
  DataProvider
}
