import { IPublicClientApplication } from '@azure/msal-browser';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createRoutesFromElements, RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { AuthProvider, DataProvider } from '~/components/Context';
import { routes } from './App.routes';
import theme from './App.theme';

interface AppModel {
  instance: IPublicClientApplication
}

const App: React.FC<AppModel> = ({ instance }) => {
  const router = createBrowserRouter(createRoutesFromElements(routes));

  return (
    <AuthProvider msal={instance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DataProvider>
          <RouterProvider router={router} />
        </DataProvider>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App;
