import { AssessmentRepository } from '~/repositories';
import { AssessmentModel, IAssessment } from '~/models/Assessment';

class AssessmentService {
  private repository: AssessmentRepository;

  constructor() {
    this.repository = AssessmentRepository.Instance();
  }

  // partitionKey = Capability.id
  async deleteAssessment(id: string, partitionKey: string) {
    return await this.repository.deleteById(id, partitionKey);
  }

  async getAssessment(id: string): Promise<AssessmentModel> {
    const assessment = await this.repository.getById(id);
    return new AssessmentModel(assessment);
  }

  async getAssessmentList(userId: string): Promise<AssessmentModel[]> {
    const { results: assessmentList } = await this.repository.searchByField('UserId', userId);

    // order by CreatedDate
    return assessmentList.orderBy<IAssessment>(a => a.CreatedDate.getTime()).map(a => new AssessmentModel(a));
  }

  async saveAssessment(assessment: any): Promise<AssessmentModel> {
    return this.repository.upsert(assessment);
  }
}

export default AssessmentService;
