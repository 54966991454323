import { CosmosClient } from '@azure/cosmos';
import { AccountInfo } from '@azure/msal-browser';
import env from '~/services/Env';
import { IdHelper } from './IdHelper';

let ClientInstance: DataClient;

export class DataClient {
  private cosmosClient: CosmosClient;

  user: AccountInfo;

  get db() {
    return this.cosmosClient?.database(env('COSMOS_DB'));
  }

  static Instance(user: AccountInfo) {
    if (!ClientInstance) {
      ClientInstance = new DataClient(user);
    }
    return ClientInstance;
  }

  constructor(user: AccountInfo) {
    this.user = user;
    this.cosmosClient = new CosmosClient({
      endpoint: env('COSMOS_ENDPOINT'),
      key: env('COSMOS_MASTERKEY')
    });
  }

  getRandomId() {
    return new IdHelper().randomId();
  }
}
