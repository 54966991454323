import { Route } from 'react-router-dom';
import { Home } from '~/pages/Home';
import { RouteGuard } from '~/components/RouteGuard';
import { Capability, CapabilityLoader } from '~/pages/Capability';
import { SelfAssessment, SelfAssessmentLoader } from '~/pages/SelfAssessment';
import Root from '~/pages/Root';

export const routeList = [
  { path: '/self-assessment', element: <SelfAssessment />, loader: SelfAssessmentLoader },
  { path: '/self-assessment/:assessmentId', element: <SelfAssessment />, loader: SelfAssessmentLoader },
  { path: '/:capabilityName', element: <Capability />, loader: CapabilityLoader },
];

export const routes = (
  <Route element={<Root />}>
    {/* <Route path="*" element={<NotFound />} /> */}
    <Route path="/" element={<Home />} />
    <Route element={<RouteGuard />}>
      {routeList.map((r) => (
        <Route key={r.path} path={r.path} loader={r.loader} element={r.element}></Route>
      ))}
    </Route>
  </Route>
);
