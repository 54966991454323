import { Entry } from 'contentful';
import { ContentfulModel } from './Contentful';
import { DataHelper } from '~/repositories/util';
import data from '~/repositories/Assessments.json';

export class CapabilityModel extends ContentfulModel {
  get name(): string {
    return this.fields?.name;
  }
  get slug(): string {
    return this.fields?.slug;
  }
  get tag(): string {
    return this.fields?.tag;
  }

  constructor(entry: Entry<any>) {
    super(entry);
  }

  /**
   * Generates Fake Capability Data
   * @return {Objecy} The Capability
   */
  public static GenerateCapability(): CapabilityModel {
    const items: Entry<any>[] = (data as any).items;
    const entries = items.filter(i => i.sys.contentType.sys.id === 'capability');
    const entry = DataHelper.randomValue(entries);

    return new CapabilityModel(entry);
  }
}

