import { SyntheticEvent } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SkillModel } from '~/models';
import './Skills.scss'

interface SkillsModel {
  isSelfAssessment?: boolean,
  open: any,
  onClose: any,
  skill?: SkillModel,
}

const Skills: React.FC<SkillsModel> = ({ skill, open, onClose, isSelfAssessment = false }) => {
  const isSelected = (skill: SkillModel, idx: number) => {
    return skill.value === idx + 1
      ? 'selected'
      : '';
  }

  const handleClose = () => {
    if (onClose) {
      onClose.call(this);
    }
  };

  const handleHeadingClick = (e: SyntheticEvent) => {
    if (isSelfAssessment) {
      const { target } = e.nativeEvent as any;
      const value = JSON.parse(target.dataset['value']);
      onClose.call(this, skill?.id, value);
    }
  };

  return (
    <Modal
      id="modal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose.bind(this)}
      closeAfterTransition
    >
      <Box sx={style}>
        <IconButton size="large" onClick={handleClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <article>
          <Typography variant="h1">{skill?.name}</Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{
            __html: skill?.description
          }} />
        </article>
        <aside>
          <ul>
            {skill?.levels.map((level, idx) => (
              <li key={idx} className={isSelected(skill, idx)}>
                <h2
                  className={isSelfAssessment ? 'link' : ''}
                  onClick={handleHeadingClick}
                  data-value={idx + 1}
                >
                  {level.name}
                </h2>
                <p dangerouslySetInnerHTML={{
                  __html: level?.description
                }}></p>
              </li>
            ))}
          </ul>
        </aside>
      </Box>
    </Modal>
  )
};

const style = {
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 8,
  boxShadow: 24,
  height: '80%',
  left: '50%',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
};

export default Skills;
