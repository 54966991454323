import { Entry } from 'contentful';
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulModel } from './Contentful';
import { CapabilityModel } from './Capability';

export class CapabilityTitleModel extends ContentfulModel {
  get name(): string {
    return this.fields?.name;
  }
  get description(): string {
    const options = {
      renderNode: {
        [BLOCKS.UL_LIST]: (node: any, next: any) =>
          `<ul>${next(node.content)}</ul>`,
        [BLOCKS.LIST_ITEM]: (node: any, next: any) =>
          `<li>${next(node.content)}</li>`,
      }
    }
    return documentToHtmlString(this.fields?.description, options);
  }
  get capability(): CapabilityModel {
    return new CapabilityModel(this.fields?.capability);
  }

  constructor(entry: Entry<any>) {
    super(entry);
  }
}
