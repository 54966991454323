import { Entry } from 'contentful';

export class ContentfulModel {
  fields: any;
  id: string;
  sys: { id: string };

  constructor(entry: Entry<any>) {
    this.fields = entry.fields;
    this.id = entry.sys.id;
    this.sys = { id: entry.sys.id };
  }
}
