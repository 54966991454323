import { createContext } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

interface AuthContextState {
  msal?: IPublicClientApplication | undefined;
  user?: AccountInfo | undefined;
}

interface AuthProviderModel {
  msal: IPublicClientApplication
  children: any
}

const AuthContext = createContext<AuthContextState>({});

const AuthProvider: React.FC<AuthProviderModel> = ({ msal, children }) => {
  const user = msal.getActiveAccount();

  return (
    <MsalProvider instance={msal}>
      <AuthContext.Provider
        value={{
          msal,
          user,
        }}
      >
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  )
};



export {
  AuthContext,
  AuthProvider
}
