import { AccountInfo } from '@azure/msal-browser';
import { DataHelper, IdHelper } from '~/repositories/util';
import { CapabilityModel } from './Capability';
import { CompetencyModel } from './Competency';
import { TitleModel } from './Title';

export interface IAssessment {
  id?: string
  Name?: string
  Capability: CapabilityModel;
  Competencies: CompetencyModel[];
  CreatedDate: Date;
  Title: TitleModel;
  UserId: string;
}

export class AssessmentModel implements IAssessment {
  // id needs to be lower case so that CosmosDb uses it as the record identifier
  id?: string;
  Name?: string;
  Capability: CapabilityModel;
  Competencies: CompetencyModel[];
  CreatedDate: Date;
  Title: TitleModel;
  UserId: string;

  constructor(data?: IAssessment) {
    if (data) {
      this.id = data.id;
      this.Name = data.Name ?? data.Title.name;
      this.Capability = data.Capability;
      this.Competencies = data.Competencies;
      this.CreatedDate = data.CreatedDate;
      this.Title = data.Title;
      this.UserId = data.UserId;
    }
  }

  public static GenerateAssessment(user: AccountInfo): AssessmentModel {
    const id = new IdHelper().randomId();
    const Capability = CapabilityModel.GenerateCapability();
    const Competencies = CompetencyModel.GenerateCompetencies();
    const CreatedDate = DataHelper.randomDate();
    const Title = TitleModel.GenerateTitle();
    const UserId = user.localAccountId;

    return new AssessmentModel({
      id,
      Capability,
      Competencies,
      CreatedDate,
      Title,
      UserId,
    })
  }
}
