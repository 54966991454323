import { Entry } from 'contentful';
import { ContentfulModel } from './Contentful';

export class LevelModel extends ContentfulModel {
  get name(): string {
    return this.fields?.name;
  }
  get value(): number {
    return this.fields?.value;
  }

  constructor(entry: Entry<any>) {
    super(entry);
  }
}
