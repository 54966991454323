import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

let MsalInstance: IPublicClientApplication;

export const AuthHelper = {
  getMsal: () => {
    if (!MsalInstance) {
      MsalInstance = new PublicClientApplication(msalConfig);
    }

    return MsalInstance;
  }
}