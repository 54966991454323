import { Entry } from 'contentful';
import { ContentfulModel } from './Contentful';
import { SkillModel } from './Skill';
import data from '~/repositories/Assessments.json';

export class CompetencyModel extends ContentfulModel {
  selected = false;
  skills: SkillModel[] = []

  get name(): string {
    return this.fields?.name;
  }
  get order(): string {
    return this.fields?.order;
  }

  constructor(entry: Entry<any>) {
    super(entry);
  }

  /**
   * Generates Fake Competency Skills
   * @return {Objecy} The Competency
   */
  public GenerateSkills() {
    this.skills = SkillModel.GenerateSkills();
    return this;
  }

  /**
   * Generates Fake Competency Data
   * @return {Objecy} The Competency
   */
  public static GenerateCompetencies(): CompetencyModel[] {
    const items: Entry<any>[] = (data as any).items;
    const entries = items.filter(i => i.sys.contentType.sys.id === 'competency');
    const culture = entries.find(e => e.fields.name === 'Culture');
    const delivery = entries.find(e => e.fields.name === 'Delivery');
    const leading = entries.find(e => e.fields.name === 'Leading');
    const craft =  entries.find(e => e.fields.name === 'Craft');

    const competencies: CompetencyModel[] = [
      new CompetencyModel(culture).GenerateSkills(),
      new CompetencyModel(delivery).GenerateSkills(),
      new CompetencyModel(leading).GenerateSkills(),
      new CompetencyModel(craft).GenerateSkills(),
    ];

    return competencies;
  }
}
