import XRegExp from 'xregexp';
import XRegExpLookBehind from 'xregexp-lookbehind';
import { SqlQuerySpec } from '@azure/cosmos';
import { merge } from 'lodash';
import { IKeyValuePair } from '~/components/Context';
import { ICollection, IQueryResults } from '../Base';
import { IdHelper } from './IdHelper';

// add lookbehind support
merge(XRegExp, XRegExpLookBehind);

interface IData {
  [key: string]: ICollection;
}
const Data: IData = {};

export const DataHelper = {
  createQuery: (query: string, params: {[key: string]: any} = {}): SqlQuerySpec => {
    return {
      query,
      parameters: Object.keys(params).map(key => ({name: key, value: params[key]}))
    }
  },
  parseValue: (value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value?.toString();
    }
  },
  parseQuery: (query, args) => {
    if (args) {
      const formatArg = (arg) => {
        const format = {
          number: () => { return arg; },
          string: () => { return `"${arg}"`; },
          boolean: () => { return arg; },
          object: () => { return Array.isArray(arg) ? `[${arg.map(a => `'${a}'`).join(',')}]` : arg; },
        }[typeof arg];

        return format();
      };

      Object.keys(args).forEach(key => { query = query.replace(key, formatArg(args[key])); });
    }

    const caseSensative = false;
    if (query.contains('WHERE', caseSensative)) {
      const queryFunctions = {
        contains: (value) => (target) => { return target.contains(value); },
        array_contains: (target) => (value) => { return target.includes(value); },
        stringequals: (value) => (target) => { return target === value; },
      }

      // (?<key>(\w+\()?(\w\.\w+)(\))?)\s?=\s?(?<value>(?<=(\=(\s)?))(\"?.+\"?\s?))
      // const EXPRESSION_PATTERN2 = new XRegExp([
      //   '(?<key>(\\w+\\()?(\\w\\.\\w+)(\\))?)',
      //   '\\s?=\\s?',
      //   '(?<value>(?<=(\\=(\\s)?))(\\"?.+\\"?\\s?))',
      // ].join(''), 'ig');

      const EXPRESSION_PATTERN = XRegExp([
        '(?<WHERE>WHERE\\s?.+)',
        '(?<ORDER>ORDER\\sBY.+)?'
      ].join(''), 'ig');

      const map = {};
      XRegExp.forEach(query, EXPRESSION_PATTERN, match => {
        const parts = match.WHERE.trimStart('WHERE').split('AND').map(x => x.trim());
        parts.forEach(part => {
          if (part.contains('=')) {
            // comparison
            const kvp = part.split('=').map(x => x.trim());
            const keyExpression = /(?<=\.)\w+/i.exec(kvp[0]) || [''];
            const valueExpression = /\w+(_|-|\+|&)?\w+/i.exec(kvp[1]) || [''];
            const key = keyExpression[0];
            const value = valueExpression[0];

            map[key] = DataHelper.parseValue(value);
          } else {
            // function
            const FUNCTION_EXPRESSION = XRegExp([
              '(?<func>\\w+)',
              '\\(',
              '(?<key>.+)',
              ',(\\s)?',
              '(?<value>.+)\\)'
            ].join(''), 'ig');
            XRegExp.forEach(part, FUNCTION_EXPRESSION, m => {
              const { key: matchKey, value: matchValue } = m.value.contains('.') ? { key: m.value, value: m.key} : { key: m.key, value: m.value};
              const keyExpression = /(?<=\.)\w+/i.exec(matchKey) || [''];
              const key = keyExpression[0];

              map[key] = queryFunctions[m.func.toLowerCase()](DataHelper.parseValue(matchValue));
            });
          }
        });
      });

      // XRegExp.forEach(query, EXPRESSION_PATTERN, match => {
      //   const keyExpression = /(?<=\.)\w+/i.exec(match.key) || [''];
      //   const valueExpression = /\w+(_|-|\+|&)?\w+/i.exec(match.value) || [''];
      //   const key = keyExpression[0];
      //   const value = valueExpression[0];

      //   map[key] = value;
      // });

      return item => {
        return Object.keys(map).reduce((result, key) => {
          if (result) {
            // tslint:disable-next-line: prefer-conditional-expression
            if (typeof map[key] === 'function') {
              result = map[key](item[key]);
            } else {
              result = DataHelper.parseValue(item[key]) === map[key];
            }
          }
          return result;
        }, true);
      };
    }
    return item => item;
  },
  register: (containerName, collection) => {
    Data[containerName] = collection;
  },
  randomId: () => {
    return new IdHelper().randomId();
  },
  randomValue: (values: any[]) => {
    return values[Math.floor(Math.random() * values.length)];
  },
  randomDate: (): Date => {
    const start = new Date(2000, 0, 1);
    const end = new Date();
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

    return date;
  },

  // CRUD
  getAll: (data: any[]) => {
    return { results: data };
  },
  getById: (data: any[], id) => {
    const { results: items } = DataHelper.query(data,
      `SELECT * FROM root r WHERE r.id=@id`,
      {
        '@id': id,
      }
    );
    return items.firstOrDefault();
  },
  getByField: <T = any>(data: any[], fields: {[key: string]: any}) => {
    return DataHelper.query<T>(data,
      `SELECT * FROM root r WHERE ${Object.keys(fields).map((key, index) => `ToString(r.${key})=@param${index}`).join(' AND ')}`,
      Object.keys(fields).reduce((acc: object, key: string, index: number) => ({
        ...acc,
        [`@param${index}`]: fields[key].toString()
      }), {})
    );
  },
  deleteById: (data: any[], id) => {
    const index = data.findIndex(item => item.id === id);
    if (index > -1) {
      data.splice(index, 1);
      return true;
    }
    throw new Error('Not Found');
  },
  replaceById: (data: any[], id, entity) => {
    const index = data.findIndex(x => x.id === id);
    if (index > -1) {
      data[index] = entity;
      return { resource: entity };
    }
    throw new Error('Not Found');
  },
  searchByField: <T = any>(data: any[], fieldName: string, query: string, additionalQueries: IKeyValuePair<any> = {}) => {
    return DataHelper.query<T>(data,
      `SELECT *
      FROM root r
      WHERE CONTAINS(r.${fieldName}, @param)${[
        '', ...Object.keys(additionalQueries).map((key, index) => `r.${key}=@param${index}`)
       ].join(' AND ')}`,
     {
       '@param': query.toUpperCase(),
       ...Object.keys(additionalQueries).reduce((acc: object, key: string, index: number) => ({
         ...acc,
         [`@param${index}`]: additionalQueries[key].toString()
       }), {})
     }
    );
  },
  query: <T = any>(data: any[], query: string, args: object): IQueryResults<T> => {
    const expression = DataHelper.parseQuery(query, args);
    const items = data.filter(expression);
    return {
      results: items
    };
  },
  upsert: (data: any[], entity) => {
    const index = data.findIndex(x => x.id === entity.id);
    if (index > -1) {
      data[index] = entity;
    } else {
      data.push(entity);
    }
  }
};
