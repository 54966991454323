import { Entry } from 'contentful';
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { DataHelper } from '~/repositories/util';
import { ContentfulModel } from './Contentful';
import { CapabilityModel } from './Capability';
import { CapabilityTitleModel } from './CapabilityTitle';
import data from '~/repositories/Assessments.json';

export class TitleModel extends ContentfulModel {
  selected = false;

  get name(): string {
    return this.fields?.name;
  }
  get abbr(): string {
    return this.fields?.abbr;
  }
  get order(): string {
    return this.fields?.order;
  }
  get description(): any {
    const options = {
      renderNode: {
        [BLOCKS.UL_LIST]: (node: any, next: any) =>
          `<ul>${next(node.content)}</ul>`,
        [BLOCKS.LIST_ITEM]: (node: any, next: any) =>
          `<li>${next(node.content)}</li>`,
      }
    }
    return documentToHtmlString(this.fields?.description, options);
  }
  get capability(): CapabilityModel[] {
    return this.fields?.capability.map((c: any) => new CapabilityModel(c))
  }
  get capabilityDescriptions(): CapabilityTitleModel[] {
    return this.fields?.capabilityDescriptions?.map((ct: any) => new CapabilityTitleModel(ct))
  }

  constructor(entry: Entry<any>) {
    super(entry);
  }

  /**
   * Generates Fake Title Data
   * @return {Objecy} The Title
   */
  public static GenerateTitle() {
    const items: Entry<any>[] = (data as any).items;
    const entries: any[] = items.filter(i => i.sys.contentType.sys.id === 'title');
    const entry = DataHelper.randomValue(entries);

    return new TitleModel(entry);
  }
}
