/* eslint-disable @typescript-eslint/no-unused-vars */

interface ICombineOptions {
  arrayBehavior: 'merge' | 'replace';
}

interface IGroup<T> {
  key: any;
  values: T[];
}

interface Array<T> {
  count(expression: any): number;
  elementAt<T>(index: number): T;
  first(expression: any): T;
  firstOrDefault(defaultValue?: any): T;
  groupBy<T = any>(expression: (element: T) => any): Array<IGroup<T>>;
  orderBy<T>(expression: (element: T) => any): T[];
  orderByDescending(expression: any): T;
  skip(count: number): T[];
  take(count: number | undefined): T[];
}

interface ArrayConstructor {
  first(array: number & any[]): any;
  last(array: any[]): any;
}

interface ObjectConstructor {
  combine<T = any>(input: any, ...sources: any): T;
  copy(input: any): any;
  get(object: any, path: any, defaultValue: any): any;
  isFunction(value: any): boolean;
  isNil(value: any): boolean;
  isNumber(value: any): boolean;
  isNumOrStr(value: any): boolean;
  isObject(value: any): boolean;
  isObjectLike(value: any): boolean;
  isString(value: any): boolean;
  parseValue(value: any): any;
}

interface String {
  endsWith(value: string, ignoreCase?: boolean): boolean;
  contains(value: string): boolean;
  startsWith(value: string): boolean;
  toSlug(): string;
  toNumber(): number;
}

interface StringConstructor {
  isNullOrEmpty(target: string): boolean;
  trimEnd(target: string, value: string): string;
  trimStart(target: string, value: string): string;
}

const deepCopy = (obj: object) => {
  return !!obj ? JSON.parse(JSON.stringify(obj)) : null;
};

const getType = (value: any) => {
  if (value === null) {
    return 'null';
  }
  if (typeof value === 'undefined') {
    return 'undefined';
  }
  if (typeof value === 'object') {
    return Array.isArray(value) ? 'array' : 'object';
  }

  return typeof value;
};

const clone = (input: any) => {
  function cloneValue(value: any) {
    // The value is an object so lets clone it.
    if (getType(value) === 'object') {
      return cloneObject(value);
    }
    // The value is an array so lets clone it.
    if (getType(value) === 'array') {
      return cloneArray(value);
    }

    // Any other value can just be copied.
    return value;
  }

  function cloneArray(target: any[]): any[] {
    return target.map(cloneValue);
  }

  function cloneObject(target: any) {
    const result: any = {};

    Object.keys(target).forEach(key => {
      if (target.hasOwnProperty(key)) result[key] = cloneValue(target[key]);
    });

    return result;
  }

  const inputType = getType(input);
  switch (inputType) {
    case 'object':
      return cloneObject(input);
    case 'array':
      return cloneArray(input);
    default:
      return input;
  }
};

const getTag = (value: any) => {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]'
  }
  return Object.prototype.toString.call(value)
}

const isSymbol = (value: any) => {
  const type = typeof value;
  return (
      type === 'symbol' ||
      (type === 'object' && value != null && getTag(value) === '[object Symbol]')
  );
}

const isKey = (value: any, object: any) => {
  /** Used to match property names within property paths. */
  const reIsDeepProp = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/
  const reIsPlainProp = /^\w*$/

  if (Array.isArray(value)) {
    return false
  }
  const type = typeof value
  if (type === 'number' || type === 'boolean' || value == null || isSymbol(value)) {
    return true
  }
  return reIsPlainProp.test(value) || !reIsDeepProp.test(value) ||
    (object != null && value in Object(object))
}

const toKey = (value: any) => {
  const INFINITY = 1 / 0
  if (typeof value === 'string' || isSymbol(value)) {
    return value
  }
  const result = `${value}`
  return (result === '0' && (1 / value) === -INFINITY) ? '-0' : result
}

const castPath = (value: any, object: any) => {
  if (Array.isArray(value)) {
    return value
  }
  return isKey(value, object) ? [value] : undefined;
}

const baseGet = (object: any, path: any) => {
  path = castPath(path, object)

  let index = 0
  const length = path.length

  while (object != null && index < length) {
    object = object[toKey(path[index++])]
  }
  return (index && index === length) ? object : undefined
}

Object.combine = (input, ...sources) => {
  const options = {
    arrayBehavior: 'replace' // Can be "merge" or "replace".
  };

  // Ensure we have actual sources for each.
  const output = deepCopy(input || {});

  if (sources) {
    // Enumerate the objects and their keys.
    for (let i = 0; i < sources.length; i++) {
      const object = sources[i] || {};
      const keys = Object.keys(object);

      for (let idx = 0; idx < keys.length; idx++) {
        const key = keys[idx];
        const value = object[key];
        const existingValueType = getType(output[key]);
        const type = getType(value);

        switch (type) {
          case 'object':
            if (existingValueType) {
              const existingValue = existingValueType === 'object' ? output[key] : {};
              output[key] = Object.combine({}, existingValue, clone(value));
            } else {
              output[key] = clone(value);
            }
            break;
          case 'array':
            if (existingValueType === 'array') {
              const newValue = clone(value);
              output[key] =
                options.arrayBehavior === 'merge' ? output[key].concat(newValue) : newValue;
            } else {
              output[key] = clone(value);
            }
            break;
          default:
            output[key] = value;
            break;
        }
      }
    }
  }

  return output;
};

Object.copy = (input) => {
  const obj = JSON.stringify(input);
  return JSON.parse(obj);
};

Object.get = (object: any, path: any, defaultValue: any) => {
  const result = object == null ? undefined : baseGet(object, path);
  return result === undefined ? defaultValue : result;
};

Object.isFunction = (value) => {
  return typeof value === 'function';
};

Object.isNil = (value) => {
  return value == null;
};

Object.isNumber = (value) => {
  return (
    typeof value === 'number' || (Object.isObjectLike(value) && getTag(value) === '[object Number]')
  ) && !isNaN(value);
};

Object.isNumOrStr = (value: unknown): value is number | string => {
  return Object.isNumber(value as number) || Object.isString(value);
}

Object.isObject = (value) => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

Object.isObjectLike = (value) => {
  return typeof value === 'object' && value !== null
};

Object.isString = (value: unknown): value is number | string => {
  const type = typeof value;
    return (
        type === 'string' ||
        (type === 'object' &&
            value != null &&
            !Array.isArray(value) &&
            getTag(value) === '[object String]')
    );
};

Object.parseValue = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

Array.first = (array: any[]) => {
  return array != null && array.length ? array[0] : undefined;
};

Array.last = (array: any[]) => {
  const length = array == null ? 0 : array.length;
  return length ? array[length - 1] : undefined;
}

Array.prototype.count = function(expression) {
  return expression
    ? this.filter(expression).length
    : this.length;
};

Array.prototype.elementAt = function(index) {
  return this[index];
};

Array.prototype.first = function(expression) {
  if (expression) {
    return this.find(expression).first();
  }
  return this.length ? this[0] : null;
};

Array.prototype.firstOrDefault = function(defaultValue?) {
  if (defaultValue === undefined) { defaultValue = null; }
  return this.first() || defaultValue;
};

Array.prototype.groupBy = function(keyFunction) {
  const groups: any = {};

  this.forEach(function(el) {
    const key = keyFunction(el);
    if (key in groups === false) {
      groups[key] = [];
    }
    groups[key].push(el);
  });

  return Object.keys(groups).reduce((prev: any[], curr: any) => {
    prev.push({
      key: curr,
      values: groups[curr]
    });
    return prev;
  }, []);
};

Array.prototype.orderBy = function(expression) {
  return this.sort((a, b) => {
    const x = expression(a);
    const y = expression(b);
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
};

Array.prototype.orderByDescending = function(expression) {
  return this.sort((a, b) => {
    const x = expression(b);
    const y = expression(a);
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
};

Array.prototype.skip = function (count) {
  return this.slice(count);
};

Array.prototype.take = function (count) {
  return this.slice(0, !count ? this.length : count);
};

String.isNullOrEmpty = (target: string) => {
  let result = true;
  if (target) {
    result = target !== '';
  }
  return result;
};

String.trimEnd = function (target, value) {
  let result = target.toString();
  if (value.endsWith(value)) {
    const len = value.length;
    result = target.substring(0, target.length - len);
  }

  return result;
};

String.trimStart = function (target, value) {
  let result = target.toString();
  if (result && result.startsWith(value)) {
    const len = value.length;
    result = target.substring(len, target.length);
  }

  return result;
};

String.prototype.endsWith = function (value, ignoreCase) {
  const len = value.length;
  const sub = this.substring(this.length - len, this.length);
  if (ignoreCase) {
    return (sub.toLowerCase() === value.toLowerCase());
  }
  else {
    return (sub === value);
  }
};

String.prototype.contains = function (value: string, caseSensitive?: boolean) {
  const val = this || '';
  if (caseSensitive) {
    return val.indexOf(value) !== -1;
  }
  else {
    return val.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
};

String.prototype.startsWith = function (value) {
  return this.indexOf(value) === 0;
};

String.prototype.toSlug = function() {
  const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return this.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

String.prototype.toNumber = function () {
  return parseInt(this.replace(/\D+/g, ''), 10);
};
