import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './AssessmentComplete.scss'
import { useContext } from 'react';
import { DataContext } from '../Context';

interface AssessmentCompleteModel {
  open: any,
  onClose: any,
}

const AssessmentComplete: React.FC<AssessmentCompleteModel> = ({ open, onClose }) => {
  const dataContext = useContext(DataContext);
  const [_showAssessments, setShowAssessments] = dataContext.showAssessmentsState;

  const handleClose = () => {
    if (onClose) {
      onClose.call(this);
    }
  };

  const handleAssessmentsClick = () => {
    setShowAssessments(true);
    if (onClose) {
      onClose.call(this);
    }
  }

  return (
    <Modal
      id="assessment-complete"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose.bind(this)}
      closeAfterTransition
    >
      <Box sx={style}>
        <IconButton size="large" onClick={handleClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <div>
          <Typography variant="h1">Assessment Complete</Typography>
          <Typography variant="body1">
            To save your assessment, open the
            <Button variant='text' onClick={handleAssessmentsClick}>Assessments</Button>
            panel and click on the Save icon.
          </Typography>
        </div>
      </Box>
    </Modal>
  )
};

const style = {
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 8,
  boxShadow: 24,
  height: 400,
  left: '50%',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 515,
};

export default AssessmentComplete;
