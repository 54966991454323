import { Entry } from 'contentful';
import {
  CapabilityModel,
  CompetencyModel,
  ContentfulModel,
  LevelModel,
  SkillLevelModel,
  SkillModel,
  TitleModel
} from '~/models';

type ContentData = { items: any[] };

const ContentHelper = {
  modelMap: {
    'capability': CapabilityModel,
    'competency': CompetencyModel,
    'level': LevelModel,
    'skill': SkillModel,
    'skillLevel': SkillLevelModel,
    'title': TitleModel,
  },
  getContent: <T>(content: ContentData, model: typeof ContentfulModel) => {
    if (content) {
      const models = content.items.reduce<T[]>((results, item) => {
        const result = ContentHelper.getContentModel<T>(model, item);
        if (result) {
          results.push(result);
        }

        return results;
      }, []);

      return models;
    }

    return [];
  },
  getContentModel: <T>(model: new(entry: Entry<any>) => ContentfulModel, entry: Entry<any>) => {
    const contentType = entry.sys.contentType.sys.id;
    if (ContentHelper.modelMap[contentType] !== model) {
      return null;
    }
    return new model(entry) as T;
  },
}

export default ContentHelper;
