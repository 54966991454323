import { useContext, useMemo } from 'react';
import { Outlet } from 'react-router';
import { AuthContext } from '~/components/Context';

const RouteGuard = () => {
  const { msal, user } = useContext(AuthContext);

  useMemo(() => {
    if (!user) {
      msal?.loginRedirect({
        scopes: [],
      });
    }
  }, [user]);

  return (
    <>
      {user
        ? (<Outlet />)
        : (<></>)}
    </>
  )
}

export default RouteGuard;
