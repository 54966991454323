import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#1bc0c6',
    },
    secondary: {
      main: '#fda82e',
    },
    grey: {
      50: '#eee',
    },
    error: {
      main: '#fdeded'
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: { fontSize: '2.125rem', fontWeight: 700, letterSpacing: '-1.5px' },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: '-0.5px',
    },
    h3: { fontSize: '1.125rem', fontWeight: 700 },
    h4: { fontSize: '1.125rem', fontWeight: 600, letterSpacing: '-0.1px' },
    h5: { fontSize: '1.125rem', fontWeight: 500 },
    h6: { fontSize: '1.125rem', fontWeight: 500 },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '-0.2px',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '-0.2px',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.41,
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
    button: {
      fontSize: '1rem',
      lineHeight: 1.41,
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    overline: {
      fontSize: '0.75rem',
      lineHeight: 2.66,
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '-0.5px',
    },
  }
});

export default theme;
