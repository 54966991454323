import { Entry } from 'contentful';
import { ContentfulModel } from './Contentful';
import { LevelModel } from './Level';
import { SkillModel } from './Skill';
import { TitleModel } from './Title';
import data from '~/repositories/Assessments.json';

export class SkillLevelModel extends ContentfulModel {
  get name(): string {
    return this.fields?.name;
  }
  get level(): LevelModel {
    return new LevelModel(this.fields?.level);
  }
  get skill(): SkillModel {
    return new SkillModel(this.fields?.skill);
  }
  get title(): TitleModel {
    return new TitleModel(this.fields?.title);
  }

  constructor(entry: Entry<any>) {
    super(entry);
  }

  /**
   * Generates Fake SkillLevel Data
   * @return {Objecy} The SkillLevels
   */
  public static GenerateSkillLevel(title: TitleModel, skill: SkillModel) {
    const items: Entry<any>[] = (data as any).items;
    const entries: any[] = items.filter(i => i.sys.contentType.sys.id === 'skillLevel');
    const skillLevel = entries.find(e => {
      return e.fields.skill.fields.name === skill.name &&
             e.fields.title.fields.name === title.name;
    });

    return new SkillLevelModel(skillLevel);
  }
}
