import { useState } from 'react';

const useInputValue = <T>(initialValue = undefined) => {
  const [value, setValue] = useState<T>(initialValue);

  return {
    value,
    onChange: (e) => {
      const { target } = e;
      setValue(target?.value || '');
    },
  };
};

export default useInputValue;
