import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Paper, Tab, Tabs, useTheme } from '@mui/material';
import { ResponsiveContainer } from 'recharts';
import { useLoaderData } from '~/hooks';
import { ContentHelper } from '~/helpers';
import { CompetencyModel } from '~/models/Competency';
import { SkillModel } from '~/models/Skill';
import { SkillLevelModel } from '~/models/SkillLevel';
import { ISelfAssessmentLoaderData } from '~/pages/SelfAssessment';
import { DataContext } from '~/components/Context';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar } from '~/components/Chart';
import { ChartState } from '~/components/Chart/CategoricalChart';
import { RadarChart } from '~/components/Chart/RadarChart';
import { Skills } from '~/components/Skills';
import './Competencies.scss';

const Competencies = () => {
  const theme = useTheme();
  const { isSelfAssessment, isAssessmentComplete, assessment } = useLoaderData<ISelfAssessmentLoaderData>();
  const dataContext = useContext(DataContext);
  const [title] = dataContext.titleState;
  const [content] = dataContext.contentState;
  const [_assessmentComplete, setAssessmentComplete] = dataContext.assessmentCompleteState;
  const [selectedCompetency, setSelectedCompetency] = useState(0);
  const [competencies, setCompetencies] = dataContext.competenciesState;
  const [skills, setSkills] = useState<SkillModel[]>([]);
  const [skill, setSkill] = useState<SkillModel | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setCompetencies(undefined);
  }, []);

  useEffect(() => {
    const getData = () => {
      let competencies = ContentHelper.getContent<CompetencyModel>(content, CompetencyModel);
      const skillData = ContentHelper.getContent<SkillModel>(content, SkillModel);
      const skillLevelData = ContentHelper.getContent<SkillLevelModel>(content, SkillLevelModel);
      const skillLevels = skillLevelData?.filter(sl => sl.title?.abbr === title?.abbr);

      if (competencies?.length) {
        // order & map skills to competencies
        competencies = competencies
          .orderBy<CompetencyModel>(c => c.order)
          .map(c => {
            c.skills = skillData ?
              skillData
                .filter(s => s.competency.id === c.id)
                .map(s => {
                  const skillLevel = skillLevels?.find(sl => sl.skill?.id === s.id);
                  const skillAssessment = assessment?.Competencies.reduce<SkillModel>((result, competency) => {
                    const skill = competency.skills.find(x => x.id === s.id);
                    if (skill) {
                      result = skill;
                    }

                    return result;
                  }, undefined);

                  return {
                    id: s.id,
                    sys: { id: s.id },
                    fields: s.fields,
                    competency: s.competency,
                    description: s.description,
                    levels: s.levels,
                    name: s.name,
                    value: skillLevel?.level?.value,
                    assessment: skillAssessment?.assessment,
                  }
                }) :
              [];
            return c;
          })

        const competency = competencies.elementAt<CompetencyModel>(selectedCompetency);
        competency.selected = true;
        setSkills(competency.skills);
        setSelectedCompetency(selectedCompetency);
      }

      setCompetencies(competencies);
    }

    if (title) {
      getData();
    }
  }, [assessment, title]);

  useMemo(() => {
    const competency = competencies?.elementAt<CompetencyModel>(selectedCompetency)

    if (competency) {
      competencies?.forEach(c => c.selected = c.name === competency.name);
      setCompetencies(competencies);
      setSkills(competency.skills);
    }
  }, [selectedCompetency]);

  const handleAxisClick = (e: any) => {
    const { value } = e;
    const skill = skills.find(s => s.name === value);

    setSkill(skill);
    setModalOpen(true);
  }

  const handleChartClick = (e: ChartState) => {
    if (e && !isAssessmentComplete) {
      const skill = skills.find(s => s.name === e.activeLabel);
      if (skill) {
        setSkills(skills.map(s => {
          if (s.id === skill.id) {
            s.assessment = e.activeTick;
          }
          return s;
        }));

        setAssessmentComplete(competencies.reduce<boolean>((result, competency) => {
          const complete = competency.skills.every(s => !!s.assessment);
          return result && complete;
        }, true));
      }
    }
  }

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedCompetency(newValue);
  };

  const handleModalClose = (skillId: string, value: any) => {
    if (!isAssessmentComplete) {
      setSkills(skills.map(s => {
        if (s.id === skillId) {
          s.assessment = value;
        }
        return s;
      }));
    }
    setModalOpen(false);
  }

  return (
    <div id="competencies">
      <label>Competencies</label>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <AppBar color="default" position="static">
          <Tabs
            value={selectedCompetency}
            onChange={handleChange}
            textColor="inherit"
            variant='fullWidth'
            TabIndicatorProps={{
              style: {
                backgroundColor: 'transparent'
              }
            }}
          >
            {competencies?.map((competency, idx) => (
              <Tab key={idx} label={competency.name} />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      <Paper>
        <label>Skills</label>
        <ResponsiveContainer height={600} width="100%">
          <RadarChart id="chart" outerRadius="50%" data={skills} onClick={handleChartClick}>
            <PolarGrid gridType='polygon' />
            <PolarRadiusAxis type="number" domain={[0, 5]} tickCount={6} axisLine={false} tick={false} />
            <PolarAngleAxis dataKey="name" onClick={handleAxisClick} />
            <Radar
              animationDuration={100}
              dataKey="value"
              fill={theme.palette.primary.main}
              fillOpacity={0.5}
              stroke="black"
            />
            {isSelfAssessment ?
              <Radar
                animationDuration={100}
                dataKey="assessment"
                dot={{ stroke: 'black', strokeWidth: 1, r: 4, strokeDasharray:'' }}
                fill={theme.palette.secondary.main}
                fillOpacity={0.5}
                stroke="black"
              />
              : null
            }
          </RadarChart>
        </ResponsiveContainer>
      </Paper>
      <Skills
        skill={skill}
        open={modalOpen}
        isSelfAssessment={isSelfAssessment}
        onClose={handleModalClose}
      />
    </div>
  )
}

export default Competencies;
