type vars =
  'ENVIRONMENT' |
  'AZURE_CLIENT_ID' |
  'AZURE_TENANT_ID' |
  'CONTENTFUL_APP_TOKEN' |
  'CONTENTFUL_SPACE_ID' |
  'COSMOS_ENDPOINT' |
  'COSMOS_MASTERKEY' |
  'COSMOS_DB'
;


const env = (key: vars) => {
  return process.env[`REACT_APP_${key}`] || '';
}

export default env;
