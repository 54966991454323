import { Entry } from 'contentful';
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { DataHelper } from '~/repositories/util';
import { CompetencyModel } from './Competency';
import { ContentfulModel } from './Contentful';
import { SkillLevelModel } from './SkillLevel';
import { TitleModel } from './Title';
import data from '~/repositories/Assessments.json';

type LevelDescription = {
  name: string,
  description: string,
}

export class SkillModel extends ContentfulModel {
  value?: number;
  assessment?: number;

  get name(): string {
    return this.fields?.name;
  }
  get description(): any {
    const options = {
      renderNode: {
        [BLOCKS.UL_LIST]: (node: any, next: any) =>
          `<ul>${next(node.content)}</ul>`,
        [BLOCKS.LIST_ITEM]: (node: any, next: any) =>
          `<li>${next(node.content)}</li>`,
      }
    }
    return documentToHtmlString(this.fields?.description, options);
  }
  get levels(): LevelDescription[] {
    const options = {
      renderNode: {
        [BLOCKS.UL_LIST]: (node: any, next: any) =>
          `<ul>${next(node.content)}</ul>`,
        [BLOCKS.LIST_ITEM]: (node: any, next: any) =>
          `<li>${next(node.content)}</li>`,
      }
    }

    const values: LevelDescription[] = [
      { name: 'Beginner', description: documentToHtmlString(this.fields?.beginnerDescription, options) },
      { name: 'Foundational', description: documentToHtmlString(this.fields?.foundationalDescription, options) },
      { name: 'Intermediate', description: documentToHtmlString(this.fields?.intermediateDescription, options) },
      { name: 'Advanced', description: documentToHtmlString(this.fields?.advancedDescription, options) },
      { name: 'Expert', description: documentToHtmlString(this.fields?.expertDescription, options) },
    ];

    return values;
  }
  get competency(): CompetencyModel {
    return new CompetencyModel(this.fields.competency);
  }

  constructor(entry: Entry<any>) {
    super(entry);
  }

  /**
   * Generates Fake Skill Data
   * @return {Objecy} The Skills
   */
  public static GenerateSkills(): SkillModel[] {
    const items: Entry<any>[] = (data as any).items;
    const entries: any[] = items.filter(i => i.sys.contentType.sys.id === 'skill');
    const title = TitleModel.GenerateTitle();

    return entries.map(s => {
      const skill = new SkillModel(s);
      skill.value = SkillLevelModel.GenerateSkillLevel(title, skill).level.value;
      skill.assessment = DataHelper.randomValue([1, 2, 3, 4, 5]);

      return skill;
    });
  }
}
