import { KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Delete, OpenInBrowser, Save, SaveAs } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import { useInputValue, useLoaderData } from '~/hooks';
import { AssessmentModel, IAssessment } from '~/models/Assessment';
import { ISelfAssessmentLoaderData } from '~/pages/SelfAssessment';
import { AssessmentService } from '~/services';
import { DataContext } from '~/components/Context';
import './Assessments.scss';

interface AssessmentsModel {
  open: any,
  onClose: any,
}

const Assessments: React.FC<AssessmentsModel> = ({ open, onClose }) => {
  const msal = useMsal();
  const navigate = useNavigate();
  const { assessmentId } = useParams();
  const { isSelfAssessment } = useLoaderData<ISelfAssessmentLoaderData>();
  const assessmentService = useRef(new AssessmentService());
  const dataContext = useContext(DataContext);
  const [assessment, setAssessment] = dataContext.assessmentState;
  const [assessments, setAssessments] = dataContext.assessmentsState;
  const [capability] = dataContext.capabilityState;
  const [competencies] = dataContext.competenciesState;
  const [_notification, setNotification] = dataContext.notificationState;
  const [title] = dataContext.titleState;
  const [assessmentData, setAssessmentData] = useState<IAssessment>(undefined);
  const [rename, setRename] = useState(false);
  const renameInput = useInputValue<string>()

  useEffect(() => {
    if (isSelfAssessment && title) {
      setAssessmentData({
        Capability: capability,
        Competencies: competencies,
        CreatedDate: new Date(),
        Title: title,
        UserId: msal.accounts.firstOrDefault()?.localAccountId,
        Name: title.name,
      });
      renameInput.onChange({ target: { value: title.name }});
    }
  }, [isSelfAssessment, title])

  const handleClose = () => {
    // reset input
    if (rename) {
      renameInput.onChange({ target: { value: assessmentData.Name || title.name }});
      setRename(false);
    }

    if (onClose) {
      onClose.call(this);
    }
  };

  const handleRename = (e) => {
    e.stopPropagation();
    setRename(true);
  };

  const handleRenameKeyDown = (e: KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      handleRenameSave(e);
    }
  };

  const handleRenameSave = (e) => {
    e.stopPropagation();
    setAssessmentData({
      ...assessmentData,
      Name: renameInput.value,
    });

    setRename(false);
  };

  const handleOpen = (assessment: IAssessment) => () => {
    const url = `/self-assessment/${assessment.id}`;

    navigate(url);

    onClose.call(this);
  };

  const handleDelete = (assessment: IAssessment) => async (e) => {
    try {
      const result = await assessmentService.current.deleteAssessment(assessment?.id, assessment?.Capability.id);
      if (result) {
        setAssessments(assessments.filter(x => x.id !== assessment.id));
      }

      setNotification({
        open: true,
        message: 'Assessment deleted',
        type: 'success',
      });
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error deleting assessment',
        type: 'error',
      });
    }

    e.stopPropagation();
    e.preventDefault();
  };

  const handleSave = async () => {
    const assessmentToSave = {
      ...assessmentData,
      Capability: capability,
      Competencies: competencies,
      CreatedDate: new Date(),
    };

    setAssessmentData(assessmentToSave);

    try {
      const newAssessment = await assessmentService.current.saveAssessment(
        new AssessmentModel(assessmentToSave)
      );

      if (newAssessment) {
        setAssessment(newAssessment);
        setAssessments([
          ...assessments,
          newAssessment,
        ]);

        const url = `/self-assessment/${newAssessment.id}`;

        navigate(url);

        setNotification({
          open: true,
          message: 'Assessment saved!',
          type: 'success',
        });
      }
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error savng assessment',
        type: 'error',
      });
    }
  };

  const handleDrawerClick = () => {
    // reset input
    if (rename) {
      renameInput.onChange({ target: { value: assessmentData.Name || title.name }});
      setRename(false);
    }
  };

  const newAssessment = () => {
    if (isSelfAssessment && title && assessmentData && !assessmentId) {
      return (
        <ListItem id='new-assessment' disablePadding>
          <ListItemButton onClick={handleRename}>
            {rename ? (
              <TextField
                label="Name"
                size="small"
                variant="filled"
                fullWidth
                onKeyDown={handleRenameKeyDown}
                {...renameInput}
              />
            ) : (
              <ListItemText>
                <Tooltip
                  title="Click to Rename"
                  placement="right"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -20],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <span className='name'>
                    {assessmentData.Name}
                  </span>
                </Tooltip>
                <span className='createdDate'>
                  {assessment && new Intl.DateTimeFormat(navigator.language).format(assessment.CreatedDate)}
                </span>
              </ListItemText>
            )}
          </ListItemButton>
          <ListItemSecondaryAction>
            {rename ? (
              <Tooltip title="Rename" placement="left">
                <IconButton onClick={handleRenameSave}>
                  <SaveAs fontSize='small' color='action' />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Save Assessment" placement="left">
                <IconButton onClick={handleSave}>
                  <Save fontSize='small' color='action' />
                </IconButton>
              </Tooltip>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      )
    }
  };

  const assessmentItem = (assessment: IAssessment) => {
    return (
      <ListItemText>
        <Tooltip
          title={assessment.Capability.name}
          placement="right"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -35],
                  },
                },
              ],
            },
          }}
        >
          <span className='name'>{assessment.Name}</span>
        </Tooltip>
        <span className='createdDate'>
          {new Intl.DateTimeFormat(navigator.language).format(assessment.CreatedDate)}
        </span>
      </ListItemText>
    )
  };

  return (
    <Drawer
      id="assessments"
      anchor="right"
      open={open}
      onClose={handleClose}
      onClick={handleDrawerClick}
    >
      <Typography variant="h2">Assessments</Typography>
      <List>
        {assessments?.map((assessment, index) => (
          <ListItem id={assessment.id} key={`assessment-${index}`} disablePadding>
            {assessment.id === assessmentId ? (
              assessmentItem(assessment)
            ) : (
              <ListItemButton onClick={handleOpen(assessment)}>
                {assessmentItem(assessment)}
              </ListItemButton>
            )}
            <ListItemSecondaryAction>
              {assessment.id === assessmentId ? (
                <Tooltip title="Currently Open" placement="left">
                  <IconButton>
                    <OpenInBrowser fontSize='small' />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Delete Assessment" placement="left">
                  <IconButton onClick={handleDelete(assessment)}>
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {newAssessment()}
      </List>
    </Drawer>
  )
}

export default Assessments;
