import { polarToCartesian } from './util/PolarUtils';
import { ChartCoordinate, Coordinate, ChartOffset, LayoutType } from './types';

const CONVERSION_RATES: Record<string, number> = {
  cm: 96 / 2.54,
  mm: 96 / 25.4,
  pt: 96 / 72,
  pc: 96 / 6,
  in: 96,
  Q: 96 / (2.54 * 40),
  px: 1,
};
export const RADIAN = Math.PI / 180;
const PARENTHESES_REGEX = /\(([^()]*)\)/;
const MULTIPLY_OR_DIVIDE_REGEX = /(-?\d+(?:\.\d+)?[a-zA-Z%]*)([*/])(-?\d+(?:\.\d+)?[a-zA-Z%]*)/;
const ADD_OR_SUBTRACT_REGEX = /(-?\d+(?:\.\d+)?[a-zA-Z%]*)([+-])(-?\d+(?:\.\d+)?[a-zA-Z%]*)/;
const CSS_LENGTH_UNIT_REGEX = /^px|cm|vh|vw|em|rem|%|mm|in|pt|pc|ex|ch|vmin|vmax|Q$/;
const NUM_SPLIT_REGEX = /(-?\d+(?:\.\d+)?)([a-zA-Z%]+)?/;
const FIXED_CSS_LENGTH_UNITS: Array<keyof typeof CONVERSION_RATES> = Object.keys(CONVERSION_RATES);
const STR_NAN = 'NaN';

function convertToPx(value: number, unit: string): number {
  return value * CONVERSION_RATES[unit];
}

class DecimalCSS {
  static parse(str: string) {
    const [, numStr, unit] = NUM_SPLIT_REGEX.exec(str) ?? [];

    return new DecimalCSS(parseFloat(numStr), unit ?? '');
  }

  constructor(public num: number, public unit: string) {
    this.num = num;
    this.unit = unit;

    if (Number.isNaN(num)) {
      this.unit = '';
    }

    if (unit !== '' && !CSS_LENGTH_UNIT_REGEX.test(unit)) {
      this.num = NaN;
      this.unit = '';
    }

    if (FIXED_CSS_LENGTH_UNITS.includes(unit)) {
      this.num = convertToPx(num, unit);
      this.unit = 'px';
    }
  }

  add(other: DecimalCSS) {
    if (this.unit !== other.unit) {
      return new DecimalCSS(NaN, '');
    }

    return new DecimalCSS(this.num + other.num, this.unit);
  }

  subtract(other: DecimalCSS) {
    if (this.unit !== other.unit) {
      return new DecimalCSS(NaN, '');
    }

    return new DecimalCSS(this.num - other.num, this.unit);
  }

  multiply(other: DecimalCSS) {
    if (this.unit !== '' && other.unit !== '' && this.unit !== other.unit) {
      return new DecimalCSS(NaN, '');
    }

    return new DecimalCSS(this.num * other.num, this.unit || other.unit);
  }

  divide(other: DecimalCSS) {
    if (this.unit !== '' && other.unit !== '' && this.unit !== other.unit) {
      return new DecimalCSS(NaN, '');
    }

    return new DecimalCSS(this.num / other.num, this.unit || other.unit);
  }

  toString() {
    return `${this.num}${this.unit}`;
  }

  isNaN() {
    return Number.isNaN(this.num);
  }
}

function calculateArithmetic(expr: string): string {
  if (expr.includes(STR_NAN)) {
    return STR_NAN;
  }

  let newExpr = expr;
  while (newExpr.includes('*') || newExpr.includes('/')) {
    const [, leftOperand, operator, rightOperand] = MULTIPLY_OR_DIVIDE_REGEX.exec(newExpr) ?? [];
    const lTs = DecimalCSS.parse(leftOperand ?? '');
    const rTs = DecimalCSS.parse(rightOperand ?? '');
    const result = operator === '*' ? lTs.multiply(rTs) : lTs.divide(rTs);
    if (result.isNaN()) {
      return STR_NAN;
    }
    newExpr = newExpr.replace(MULTIPLY_OR_DIVIDE_REGEX, result.toString());
  }

  while (newExpr.includes('+') || /.-\d+(?:\.\d+)?/.test(newExpr)) {
    const [, leftOperand, operator, rightOperand] = ADD_OR_SUBTRACT_REGEX.exec(newExpr) ?? [];
    const lTs = DecimalCSS.parse(leftOperand ?? '');
    const rTs = DecimalCSS.parse(rightOperand ?? '');
    const result = operator === '+' ? lTs.add(rTs) : lTs.subtract(rTs);
    if (result.isNaN()) {
      return STR_NAN;
    }
    newExpr = newExpr.replace(ADD_OR_SUBTRACT_REGEX, result.toString());
  }

  return newExpr;
}

function calculateParentheses(expr: string): string {
  let newExpr = expr;
  while (newExpr.includes('(')) {
    const [_, parentheticalExpression] = PARENTHESES_REGEX.exec(newExpr)!;
    newExpr = newExpr.replace(PARENTHESES_REGEX, calculateArithmetic(parentheticalExpression));
  }

  return newExpr;
}

function evaluateExpression(expression: string): string {
  let newExpr = expression.replace(/\s+/g, '');
  newExpr = calculateParentheses(newExpr);
  newExpr = calculateArithmetic(newExpr);

  return newExpr;
}

export function safeEvaluateExpression(expression: string): string {
  try {
    return evaluateExpression(expression);
  } catch (e) {
    /* istanbul ignore next */
    return STR_NAN;
  }
}

export function reduceCSSCalc(expression: string): string {
  const result = safeEvaluateExpression(expression.slice(5, -1));

  if (result === STR_NAN) {
    // notify the user
    return '';
  }

  return result;
}

/**
 * Given an array and a number N, return a new array which contains every nTh
 * element of the input array. For n below 1, an empty array is returned.
 * If isValid is provided, all candidates must suffice the condition, else undefined is returned.
 * @param {T[]} array An input array.
 * @param {integer} n A number
 * @param {Function} isValid A function to evaluate a candidate form the array
 * @returns {T[]} The result array of the same type as the input array.
 */
export function getEveryNthWithCondition<Type>(
  array: Type[],
  n: number,
  isValid?: (candidate: Type) => boolean,
): Type[] | undefined {
  if (n < 1) {
    return [];
  }
  if (n === 1 && isValid === undefined) {
    return array;
  }
  const result: Type[] = [];
  for (let i = 0; i < array.length; i += n) {
    if (isValid === undefined || isValid(array[i]) === true) {
      result.push(array[i]);
    } else {
      return undefined;
    }
  }
  return result;
}

const PREFIX_LIST = ['Webkit', 'Moz', 'O', 'ms'];

export const generatePrefixStyle = (name: string, value: string) => {
  if (!name) {
    return null;
  }

  const camelName = name.replace(/(\w)/, v => v.toUpperCase());
  const result: Record<string, string> = PREFIX_LIST.reduce(
    (res, entry) => ({
      ...res,
      [entry + camelName]: value,
    }),
    {},
  );

  result[name] = value;

  return result;
};

export type CursorRectangle = {
  stroke: string;
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

export function getCursorRectangle(
  layout: LayoutType,
  activeCoordinate: ChartCoordinate,
  offset: ChartOffset,
  tooltipAxisBandSize: number,
): CursorRectangle {
  const halfSize = tooltipAxisBandSize / 2;

  return {
    stroke: 'none',
    fill: '#ccc',
    x: layout === 'horizontal' ? activeCoordinate.x - halfSize : offset.left + 0.5,
    y: layout === 'horizontal' ? offset.top + 0.5 : activeCoordinate.y - halfSize,
    width: layout === 'horizontal' ? tooltipAxisBandSize : offset.width - 1,
    height: layout === 'horizontal' ? offset.height - 1 : tooltipAxisBandSize,
  };
}

export type RadialCursorPoints = {
  points: [startPoint: Coordinate, endPoint: Coordinate];
  cx?: number;
  cy?: number;
  radius?: number;
  startAngle?: number;
  endAngle?: number;
};

/**
 * Only applicable for radial layouts
 * @param {Object} activeCoordinate ChartCoordinate
 * @returns {Object} RadialCursorPoints
 */
export function getRadialCursorPoints(activeCoordinate: ChartCoordinate): RadialCursorPoints {
  const { cx, cy, radius, startAngle, endAngle } = activeCoordinate;
  const startPoint = polarToCartesian(cx, cy, radius, startAngle);
  const endPoint = polarToCartesian(cx, cy, radius, endAngle);

  return {
    points: [startPoint, endPoint],
    cx,
    cy,
    radius,
    startAngle,
    endAngle,
  };
}

export function getCursorPoints(
  layout: LayoutType,
  activeCoordinate: ChartCoordinate,
  offset: ChartOffset,
): [Coordinate, Coordinate] | RadialCursorPoints {
  let x1, y1, x2, y2;

  if (layout === 'horizontal') {
    x1 = activeCoordinate.x;
    x2 = x1;
    y1 = offset.top;
    y2 = offset.top + offset.height;
  } else if (layout === 'vertical') {
    y1 = activeCoordinate.y;
    y2 = y1;
    x1 = offset.left;
    x2 = offset.left + offset.width;
  } else if (activeCoordinate.cx != null && activeCoordinate.cy != null) {
    if (layout === 'centric') {
      const { cx, cy, innerRadius, outerRadius, angle } = activeCoordinate;
      const innerPoint = polarToCartesian(cx, cy, innerRadius, angle);
      const outerPoint = polarToCartesian(cx, cy, outerRadius, angle);
      x1 = innerPoint.x;
      y1 = innerPoint.y;
      x2 = outerPoint.x;
      y2 = outerPoint.y;
    } else {
      return getRadialCursorPoints(activeCoordinate);
    }
  }

  return [
    { x: x1, y: y1 },
    { x: x2, y: y2 },
  ];
}
