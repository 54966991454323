import pMap from 'p-map';
import { Container, FeedOptions, QueryIterator, SqlQuerySpec } from '@azure/cosmos';
import { IKeyValuePair } from '~/components/Context';
import { DataClient } from './util/Client';

export interface IQueryResults<T> {
  results: T[];
  continuation?: string;
}

export interface ICollection {
  create(entity: any);
  deleteById(id: string, partitionKey: string): Promise<boolean>;
  getAll(): Promise<IQueryResults<any>>;
  getById(id: string): Promise<any>;
  getByField(fields: IKeyValuePair<any>);
  parse(iterator: QueryIterator<any>): Promise<IQueryResults<any>>;
  query(query: string, params?: IKeyValuePair<any>, options?: FeedOptions): Promise<IQueryResults<any>>;
  searchByField(fieldName: string, query: string, additionalQueries?: IKeyValuePair<any>): Promise<IQueryResults<any>>;
  upsert(entity: object): Promise<any>;
  upsertList(entities: object[]);
}

export class BaseCollection implements ICollection {
  private readonly collection: Container;
  private readonly transform: (item: any) => any;
  client: DataClient;

  constructor(client: DataClient, collectionName: string, transform: any) {
    this.client = client;
    this.transform = transform;
    this.collection = client.db.container(collectionName);
  }

  create(entity: any): any {
    return this.transform(this.collection.items.create(entity));
  }

  async deleteById(id: string, partitionKey: string) {
    const item = this.collection.item(id, partitionKey);
    if (item) {
      try {
        await item.delete();
        return true;
      } catch (err) {
        console.error(`err: ${id}`, err);
        return false;
      }
    }
    return false;
  }

  async getAll(): Promise<IQueryResults<any>> {
    return this.parse(this.collection.items.readAll())
  };

  async getByField(fields: {[key: string]: any}): Promise<IQueryResults<any>> {
    return this.query(
      `SELECT * FROM root r WHERE ${Object.keys(fields).map((key, index) => `ToString(r.${key})=@param${index}`).join(' AND ')}`,
      Object.keys(fields).reduce((acc: object, key: string, index: number) => ({
        ...acc,
        [`@param${index}`]: fields[key].toString()
      }), {})
    );
  }

  async getById(id: string): Promise<any> {
    const { results: [item] } = await this.query(
      `SELECT * FROM root r WHERE r.id=@id`,
      {
        '@id': id,
      }
    );

    return this.transform(item);
  }

  async parse(iterator: QueryIterator<any>): Promise<IQueryResults<any>> {
    let continuation: string | undefined;
    const results: any[] = []
    while (iterator.hasMoreResults()) {
      const { resources, continuationToken } = await iterator.fetchNext();
      if (!resources || !resources.length) {
        // no more results
        break;
      }

      continuation = continuationToken;
      results.push(...resources.map(this.transform));
    }

    return {
      results,
      continuation,
    };
  }

  async query(query: string, params?: IKeyValuePair<any>, options?: FeedOptions): Promise<IQueryResults<any>> {
    return this.parse(
      this.collection.items.query(
        this.createQuery(query, params),
        options
      )
    );
  }

  async searchByField(fieldName: string, query: string, additionalQueries: IKeyValuePair<any> = {}): Promise<IQueryResults<any>> {
    return this.query(
      `SELECT *
       FROM root r
       WHERE CONTAINS(UPPER(ToString(r.${fieldName})), @param)${[
         '', ...Object.keys(additionalQueries).map((key, index) => `ToString(r.${key})=@param${index}`)
        ].join(' AND ')}`,
      {
        '@param': query.toUpperCase(),
        ...Object.keys(additionalQueries).reduce((acc: object, key: string, index: number) => ({
          ...acc,
          [`@param${index}`]: additionalQueries[key].toString()
        }), {})
      }
    );
  }

  async upsert(item): Promise<any> {
    const { item: { id } } = await this.collection.items.upsert(item);
    item.id = id;

    return this.transform(item);
  }

  async upsertList(items: any[]): Promise<any> {
    const results = await pMap(items, async item => {
      try {
        const result = await this.collection.items.upsert(item);
        console.info(`upserted item ${result.item.id} into ${result.item.container.database.id}`);
        return result.item;
      } catch (error) {
        console.error('error', error);
      }
    }, { concurrency: 5, stopOnError: false });

    return results;
  }

  private createQuery(query: string, params: {[key: string]: any} = {}): SqlQuerySpec {
    return {
      query,
      parameters: Object.keys(params).map(key => ({name: key, value: params[key]}))
    }
  }
}
