import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Alert, Snackbar } from '@mui/material';
import { DataContext } from '~/components/Context';
import { Layout } from '~/components/Layout';
import { Header } from '~/components/Header';

const Root = () => {
  const { pathname } = useLocation();
  const dataContext = useContext(DataContext);
  const [notification, setNotification] = dataContext.notificationState;

  const handleClose = () => {
    setNotification({
      open: false,
      message: undefined,
      type: undefined,
    });
  }

  return (
    <Layout>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleClose}
        open={notification.open}
      >
        <Alert
          onClose={handleClose}
          severity={notification.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      {pathname !== '/' && (<Header />)}
      <Outlet />
    </Layout>
  )
}

export default Root;
