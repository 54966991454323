import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoaderData } from '~/hooks';
import { ContentHelper } from '~/helpers';
import { AssessmentService, ContentService, ContentType, StorageService } from '~/services';
import { CapabilityModel } from '~/models/Capability';
import { AuthContext, DataContext } from '~/components/Context';
import { Tag } from '~/components/Context/DataContext';
import { Assessments } from '~/components/Assessments';
import { CapabilityInfo } from '~/components/CapabilityInfo';
import { Competencies } from '~/components/Competencies';
import './Capability.scss';

export interface ICapabilityLoaderData {
  content: any;
  params?: any;
}

export const CapabilityLoader = async (data: any): Promise<ICapabilityLoaderData> => {
  const contentService = new ContentService();
  const storageService = new StorageService();

  // clear cache
  storageService.removeItem('selfAssessment');
  const capabilities = await contentService?.getContentType(CapabilityModel, ContentType.Capability);
  capabilities?.forEach(capability => {
    const tag = Tag[capability.slug];
    storageService.removeItem(tag);
  });

  // set capability data
  const { capabilityName } = data?.params;
  const tag = Tag[capabilityName];
  const content = await new ContentService().getContentByTag(tag);
  await storageService.setItem(tag, content);

  return {
    content
  };
}

const Capability = () => {
  const { capabilityName } = useParams();
  const { user } = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const [showAssessments, setShowAssessments] = dataContext.showAssessmentsState;
  const [_assessments, setAssessments] = dataContext.assessmentsState;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { content } = useLoaderData();

  useEffect(() => {
    const loadAssessments = async () => {
      const assessmentService = new AssessmentService();
      setAssessments(
        await assessmentService.getAssessmentList(user.localAccountId)
      );
    };

    // get assessments
    loadAssessments();
  }, []);

  useEffect(() => {
    if (content && dataContext.capabilityState) {
      const [_capability, setCapability] = dataContext.capabilityState;
      const [_content, setContent] = dataContext.contentState;

      const getCapabilities = () => {
        const capabilities = ContentHelper.getContent!<CapabilityModel>(content, CapabilityModel);
        const capability = capabilities?.find(c => c.slug === capabilityName);

        setCapability(capability);
      }

      getCapabilities();
      setContent(content);
    }
  }, [content]);

  useMemo(() => {
    if (showAssessments) {
      setDrawerOpen(true);
    }
  }, [showAssessments]);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setShowAssessments(false);
  }

  return (
    <section id="capability">
      <CapabilityInfo />
      <div className="spacer"></div>
      <Competencies />
      <Assessments open={drawerOpen} onClose={handleDrawerClose} />
    </section>
  )
}

export default Capability;
