import { Container } from '@mui/material';

interface LayoutModel {
  children: any
}

const Layout: React.FC<LayoutModel> = ({ children }) => {

  return (
    <Container sx={{ paddingTop: 2 }}>
      {children}
    </Container>
  )
};

export default Layout;
